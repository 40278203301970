<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="大区事务所">
              <a-cascader
                :options="dealerList"
                v-model="regionalOffice"
                change-on-select
                placeholder="请选择大区/事务所"
                @change="onChange"
                :field-names="{ label: 'title', value: 'id', children: 'children' }"
              />
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.dealerName" allowClear placeholder="经销商名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <!-- <a-button type="primary" @click="toHandler('add')">分配</a-button> -->
        <a-button type="primary" v-if="selectedRows.length == 1 && selectedRows[0].status == 1" @click="toHandler('audio')">审核</a-button>
<!--         <a-button-->
<!--          v-if="selectedRows.length > 1  && selectedRows[0].status == 1"-->
<!--          type="primary"-->
<!--          @click="toHandlerAll"-->
<!--          >批量审核</a-button-->
<!--        >-->
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="status" slot-scope="text">
          <span> {{ text | status }} </span>
        </span>
        <span slot="quotaStatus" slot-scope="text">
          <span v-if="text == 1">未过期</span>
          <span v-if="text == 2">已过期</span>
        </span>
        <span slot="firmName" slot-scope="text, row">
          【{{ row.areaName }}】 {{ row.firmName }}
        </span>
        <span slot="expireDate" slot-scope="text,row">
           <span v-if="row.startingTime != null && row.expireDate">{{row.startingTime}} 至 {{row.expireDate}}</span>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerTemporaryQuotaRecord-edit-modal
        ref="DealerTemporaryQuotaRecordEditModal"
        @reload="getData"
      ></DealerTemporaryQuotaRecord-edit-modal>

      <!-- 审核 -->
      <AudioPage ref="AudioPage" @reload="getData"></AudioPage>

       <a-modal v-model="visibleAll" title="批量审核" @ok="handleOkAll"  @cancel="handleCanceAll" :maskClosable="false" width="40%" :confirm-loading="confirmLoading">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="statusAll">
              <a-radio :value="4"> 通过 </a-radio>
              <a-radio :value="3"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea v-model="rejectReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
    </a-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerTemporaryQuotaRecordEditModal from './components/DealerTemporaryQuotaRecordEditModal.vue'
import AudioPage from './components/AudioPage.vue'
import {
  delDealerTemporaryQuotaRecord,
  listDealerTemporaryQuotaRecord,
  selectByIdDealerTemporaryQuotaRecord,
} from './api/DealerTemporaryQuotaRecordApi'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerTemporaryQuotaRecord',
  components: {
    DealerTemporaryQuotaRecordEditModal,
    AudioPage
  },
  data() {
    return {
      columns: columns,
      statusAll:4,
      confirmLoading:false,
      rejectReason:'',
      visibleAll:false,
      dataList:[],
      tableData: [],
      dealerList: [],
      regionalOffice: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
       page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
    }
  },

  filters: {
    status(type) {
      const typeMap = {
        0: '草稿',
        1: '待审核',
        2: '审核通过',
        3: '审核拒绝',
      }
      return typeMap[type]
    },
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
       // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.status === 1
      })

      if (everyResult) {
        this.visibleAll = true;
        this.dataList = this.selectedRows
      }else{
        this.$notification.error({ message: '请选择相同待审核记录' })

      }
    },
    handleOkAll(){
      this.confirmLoading = true
      let params = {
              authStatus: this.statusAll,
              verifyRemark: this.rejectReason,
           }
         params.ids = this.dataList.map((item) => item.id)
        this.axios.post('/api/base/customer/dealerTemporaryQuotaRecord/updateList', params).then(res => {
          if (res.code == 200) {
          this.$message.success(res.message)
          this.visibleAll = false
          this.getData()
          }
         }).finally(() => {
           this.confirmLoading = false
         })
    },
    handleCanceAll(){
      this.confirmLoading = false
      this.visibleAll = false
      this.statusAll = ''
      this.rejectReason = ''
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listDealerTemporaryQuotaRecord({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1
      this.page.pageSize = 10
      this.regionalOffice = []
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerTemporaryQuotaRecordEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerTemporaryQuotaRecordEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerTemporaryQuotaRecord(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'audio':
          _this.$refs.AudioPage.show(_this.selectedRows[0], 'audio')
          break
      }
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },
  },
  created() {
    this.getData()
    this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      // 获取大区事务所经销商数据
      this.dealerList = res.body
    })
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
